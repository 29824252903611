import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import PostGrid from "../ui/PostGrid";
import PostGridItem from "../components/PostGridItem";
import styled from "styled-components";
import Pagination from "../components/Pagination";
import get from "lodash/get";
import theme from "../ui/theme";

const AuthorContainer = styled.div`
  @media all and (max-width: 1024px) {
    padding: 0 15px;
  }
  @media all and (max-width: ${theme.breakpoints.md}) {
    padding: 0;
  }
  .author {
    border-bottom: solid 2px #3ac0a4;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto 30px;
    padding-bottom: 40px;

    @media all and (max-width: 1024px) {
      margin: 0 auto 10px;
      max-width: 800px;
    }

    @media all and (max-width: ${theme.breakpoints.md}) {
      justify-content: center;
    }

    &__avatar {
      flex-basis: 160px;
      padding-top: 10px;
      position: relative;
      @media all and (max-width: ${theme.breakpoints.md}) {
        flex-basis: 95px;
        margin-bottom: 15px;
        padding-top: 0px;
      }
      img {
        border-radius: 160px;
        height: 160px;
        width: 100%;
        @media all and (max-width: ${theme.breakpoints.md}) {
          height: 95px;
        }
      }
      &:before {
        content: "";
        background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MiIgaGVpZ2h0PSI2MCI+PHBhdGggZD0iTS42MTggNDZoMi40QzguMDggNTMuMjU0IDE2LjQ4NSA1OCAyNiA1OHMxNy45Mi00Ljc0NiAyMi45OC0xMmgyLjRDNDYuMDY3IDU0LjQxMyAzNi42ODYgNjAgMjYgNjBTNS45MzMgNTQuNDEzLjYxOCA0NnptMC0zMkM1LjkzMyA1LjU4NyAxNS4zMTQgMCAyNiAwczIwLjA2NyA1LjU4NyAyNS4zODIgMTRoLTIuNEM0My45MiA2Ljc0NiAzNS41MTUgMiAyNiAyUzguMDggNi43NDYgMy4wMiAxNGgtMi40eiIgZmlsbD0iIzNhYzBhNCIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+")
          center center no-repeat;
        position: absolute;
        width: 100%;
        height: 185px;
        top: -2.6px;
        background-size: cover;
        @media all and (max-width: ${theme.breakpoints.md}) {
          height: 111px;
          top: -7.6px;
        }
      }
    }

    &__meta {
      flex-basis: 80%;
      @media all and (max-width: 1024px) {
        flex-basis: 70%;
      }
      @media all and (max-width: ${theme.breakpoints.md}) {
        flex-basis: 100%;
        text-align: center;
      }
      h3 {
        border-bottom: solid 2px #ffc71b;
        font-size: 36px;
        padding: 0;
        margin-bottom: 15px;
        display: inline-block;
        @media all and (max-width: ${theme.breakpoints.md}) {
          font-size: 25px;
        }
      }
    }
    &__desc {
      font-size: 16px;
      line-height: 24px;
      @media all and (max-width: ${theme.breakpoints.md}) {
        text-align: left;
      }
    }
  }
  h1 {
    font-size: 28px;
    font-weight: 600;
    margin: 0 auto 30px;
    @media all and (max-width: 1024px) {
      max-width: 625px;
    }
    @media all and (max-width: ${theme.breakpoints.md}) {
      font-size: 20px;
      margin: 0 auto 20px;
      max-width: 400px;
    }
  }
`;

const Author = ({ data, pageContext }) => {
  const { group } = pageContext;

  let authorImage = get(
    data,
    "wordpressWpUsers.avatar_urls.wordpress_96.source_url",
    "https://secure.gravatar.com/avatar/0c9e8be466cd5ccb2cc6161219f453e8?s=96&d=mm&r=g",
  );

  authorImage = authorImage.replace(
    new RegExp(
      "(?<![-A-Za-z0-9.])" +
        (process.env.GATSBY_SITE_DOMAIN || "www.travelnursing.org"),
    ),
    process.env.GATSBY_WP_DOMAIN || "old.travelnursing.org",
  );
  return (
    <Layout templateName="author">
      <Seo
        title={`${data.wordpressWpUsers.name} | ${data.site.siteMetadata.title}`}
        description={data.wordpressWpUsers.description}
        image={authorImage}
        canonical={data.wordpressWpUsers.url}
        schemaType="WebPage"
      />
      <AuthorContainer>
        <div className="author">
          <div className="author__avatar">
            <img src={authorImage} alt={data.wordpressWpUsers.name} />
          </div>
          <div className="author__meta">
            <h3>{data.wordpressWpUsers.name}</h3>
            <div
              className="author__desc"
              dangerouslySetInnerHTML={{
                __html: data.wordpressWpUsers.description,
              }}
            />
          </div>
        </div>
        <h1>Articles by {data.wordpressWpUsers.name.split(" ")[0]}</h1>
        <PostGrid>
          {group.map((node) => {
            return (
              <PostGridItem
                key={node.slug}
                slug={node.slug}
                title={node.title}
                fluidImage={get(
                  node,
                  "featured_media.localFile.childImageSharp.fluid",
                  data.placeholderImage.childImageSharp.fluid,
                )}
                date={node.date}
                content={node.content}
              />
            );
          })}
        </PostGrid>
        <Pagination context={pageContext} />
      </AuthorContainer>
    </Layout>
  );
};

export default Author;

export const query = graphql`
  query AuthorQuery($id: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    wordpressWpUsers(wordpress_id: { eq: $id }) {
      name
      description
      avatar_urls {
        dummy
        wordpress_96 {
          source_url
        }
      }
    }
    placeholderImage: file(relativePath: { eq: "default.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
